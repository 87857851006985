<template>
  <backoffice-container class="mt-2">
    <template v-if="isLoading">
      <b-spinner
        type="grow"
        variant="primary"
        small
        class="mx-auto mt-3 d-block"
      />
    </template>
    <template v-else>
      <span class="d-flex justify-content-between align-items-center">
        <h1>{{ $t('backoffice.analytics.title') }}</h1>
        <div>
        <b-button v-b-modal.export-modal-data variant="primary">
          <feather-icon icon="DownloadIcon" class="text-white" />
          {{ $t('backoffice.analytics.download') }}
        </b-button>
        </div>
      </span>
      <hr>
      <h3>{{ $t('members.title') }}</h3>
      <b-row class="mt-1 match-height">
        <!-- registered Members -->
        <b-col
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                icon="UsersIcon"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ this.$t('backoffice.analytics.registered-members') }}</p>
            </span>
            <div class="d-flex">
              <h2>
                {{
                  membersAnalytics.registeredMembers != null
                    ? membersAnalytics.registeredMembers
                    : $t('backoffice.dashboard.no-data')
                }}
              </h2>
            </div>
          </b-card>
        </b-col>
        <!-- verified Members -->
        <b-col
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                icon="UserCheckIcon"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ this.$t('backoffice.analytics.verified-members') }}</p>
            </span>
            <div class="d-flex">
              <h2>
                {{
                  membersAnalytics.verifiedMembers != null
                    ? membersAnalytics.verifiedMembers
                    : $t('backoffice.dashboard.no-data')
                }}
              </h2>
              <p class="mt-50 ml-50">
                ({{ getRatio(membersAnalytics.verifiedMembers) }}%)
              </p>
            </div>
          </b-card>
        </b-col>
        <!-- new Members Today -->
        <b-col
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                icon="UserPlusIcon"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ this.$t('backoffice.analytics.new-members-today') }}</p>
            </span>
            <div class="d-flex">
              <h2>
                {{
                  membersAnalytics.newMembersToday != null
                    ? membersAnalytics.newMembersToday
                    : $t('backoffice.dashboard.no-data')
                }}
              </h2>
              <p class="mt-50 ml-50">
                ({{ getRatio(membersAnalytics.newMembersToday) }}%)
              </p>
            </div>
          </b-card>
        </b-col>
        <!-- staff -->
        <b-col
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                icon="ShieldIcon"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ this.$t('backoffice.analytics.staff') }}</p>
            </span>
            <div class="d-flex">
              <h2>
                {{ membersAnalytics.staff != null ? membersAnalytics.staff : $t('backoffice.dashboard.no-data') }}
              </h2>
              <p class="mt-50 ml-50">
                ({{ getRatio(membersAnalytics.staff) }}%)
              </p>
            </div>
          </b-card>
        </b-col>
        <!-- connected members -->
        <b-col
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                icon="MonitorIcon"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ this.$t('backoffice.dashboard.connected-members') }}</p>
            </span>
            <div class="d-flex justify-content-between flex-wrap align-items-center">
              <div class="d-flex" :key="keyForAnalytics">
                <h2>
                  {{ totalMembersConnected || 0 }}
                </h2>
                <p class="mt-50 ml-50">
                  ({{ getRatio(totalMembersConnected) }}%)
                </p>
              </div>
              <div v-if="totalMembersConnected > 0" v-b-modal.modal-connected class="text-primary mb-25">
                <b-icon-card-checklist scale="1.2" class="mr-25" /> {{ $t('backoffice.analytics.member-list') }}
              </div>
            </div>
          </b-card>
        </b-col>
        <!-- On-site members -->
        <b-col
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <b-icon-person-lines-fill class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1" />
              <p>{{ this.$t('backoffice.analytics.on-site-members') }}</p>
            </span>
            <div class="d-flex justify-content-between flex-wrap align-items-center">
              <div class="d-flex">
                <h2>
                  {{ totalAttendance || 0 }}
                </h2>
                <p class="mt-50 ml-50">
                  ({{ getRatio(totalAttendance) }}%)
                </p>
              </div>
              <div v-if="totalAttendance > 0" v-b-modal.modal-attendees class="text-primary mb-25">
                <b-icon-card-checklist scale="1.2" class="mr-25" /> {{ $t('backoffice.analytics.member-list') }}
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <!-- Charts -->
      <!-- Connected Members -->
      <b-row class="d-flex flex-wrap match-height">
        <b-col cols="12" lg="6">
          <b-card>
            <h4>{{ $t('backoffice.dashboard.connected-members') }}</h4>
            <p>{{ $t('backoffice.dashboard.last-week') }}</p>
            <connected-members 
              :total="totalMembersLastWeek" 
              :chart-range="chartRange" 
              class="mb-1" 
            />
          </b-card>
        </b-col>
        <!-- Active Members -->
        <b-col cols="12" lg="6">
          <b-card>
            <h4>{{ $t('backoffice.analytics.active-members') }}</h4>
            <p>{{ $t('backoffice.analytics.last-minute') }}</p>
            <connected-members-last-minute
              :key="analyticsLastMinuteKey"
              :total="totalMembers"
              :last-minute="analyticsLastMinute"
              class="mb-1"
            />
          </b-card>
        </b-col>
      </b-row>

      <!-- Evolution -->
      <b-card>
        <h4>{{ $t('backoffice.analytics.members-registration') }}</h4>
        <register-evolution
          :total="totalMembers"
          :chart-range="overviewData.newMembersPerDay != null ? overviewData.newMembersPerDay.filter((item) => item.length > 0) : []"
        />
      </b-card>

      <!-- Events -->
      <h3 v-if="hasTitle(eventsAnalytics)">
        {{ $t('events.title') }}
      </h3>
      <b-row class="mt-1 match-height">
        <b-col
          v-for="(data, index) in eventsAnalytics"
          :key="index"
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card v-if="data.value != null" body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                :icon="getIcons(data)"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ getTitles(data) }}</p>
            </span>
            <h2>
              <template v-if="data.name !== 'event-duration-per-community'">
                {{ data.value != null ? data.value : $t('backoffice.dashboard.no-data') }}
              </template>
              <template v-else>
                {{ (data.value / 60).toFixed(2) }} <span class="h4"> min. </span>
              </template>
            </h2>
          </b-card>
        </b-col>
      </b-row>

      <!-- Other Analytics -->
      <h3 v-if="hasTitle(commentsAnalytics)">
        {{ $t('blogs.comments') }}
      </h3>
      <b-row class="mt-1 match-height">
        <b-col
          v-for="(data, index) in commentsAnalytics"
          :key="index"
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card v-if="data.value != null" body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                :icon="getIcons(data)"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ getTitles(data) }}</p>
            </span>
            <h2>
              <template v-if="data.name !== 'event-duration-per-community'">
                {{ data.value != null ? data.value : $t('backoffice.dashboard.no-data') }}
              </template>
              <template v-else>
                {{ (data.value / 60).toFixed(2) }} <span class="h4"> min. </span>
              </template>
            </h2>
          </b-card>
        </b-col>
      </b-row>

      <!-- Other analytics -->
      <h3>
        {{ $t('backoffice.dashboard.others') }}
      </h3>
      <b-row class="mt-1 match-height">
        <b-col
          v-for="(data, index) in othersAnalytics"
          :key="index"
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card v-if="data.value != null" body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                :icon="getIcons(data)"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ getTitles(data) }}</p>
            </span>
            <h2>
              <template v-if="data.name !== 'event-duration-per-community'">
                {{ data.value != null ? data.value : $t('backoffice.dashboard.no-data') }}
              </template>
              <template v-else>
                {{ (data.value / 60).toFixed(2) }} <span class="h4"> min. </span>
              </template>
            </h2>
          </b-card>
        </b-col>
        
        <!-- LIKES -->
        <b-col
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                :icon="getIcons('likes-seen-in-community')"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ getTitles('likes-seen-in-community') }}</p>
            </span>
            <div class="d-flex justify-content-between flex-wrap align-items-center">
              <div class="d-flex">
                <h2 v-if="totalCommunityLikes > 0">
                  {{ totalCommunityLikes }}
                </h2>
                <h2 v-else>0</h2>
              </div>
              <div v-if="totalCommunityLikes > 0" v-b-modal.modal-likes class="text-primary mb-25">
                <b-icon-card-checklist scale="1.2" class="mr-25" /> {{ $t('backoffice.analytics.member-list') }}
              </div>
            </div>
          </b-card>
        </b-col>
        
        <!-- Likes modal -->
        <b-modal
          id="modal-likes"
          hide-footer
          scrollable
          size="xl"
        >
          <template #modal-header>
            <div class="d-flex flex-column flex-lg-row justify-content-between w-100 align-items-center flex-wrap">
              <div class="d-flex w-100 justify-content-between align-items-center flex-wrap">
                <h5 id="modal-lg___BV_modal_title_" class="modal-title">
                  {{ getTitles('likes-seen-in-community') }}
                </h5>
              </div>
            </div>
            <button
              type="button"
              aria-label="Close"
              class="close order-0 order-lg-1"
              @click="closeModal('modal-likes')"
            >
              ×
            </button>
          </template>
          <b-table
            class="position-relative"
            :items="communityLikes"
            responsive
            :small="isSmall"
            :fields="likeModalOptions"
            per-page="15"
            show-empty
            empty-text="No matching records found"
          >
            <!-- Column: Member -->
            <template #cell(likedByMember)="{ item }">
              <b-media class="d-flex align-items-center pl-1 ml-25 mt-25">
                <template>
                  <b-link :to="getMemberLocation(item.likedByMember)" class="d-flex align-items-center">
                    <div>
                      <user-avatar :user="item.likedByMember" size="32" />
                    </div>
                    <div class="ml-1">
                      <p class="align-text-top mb-0 pl-25 ml-1">
                        {{ `${item.likedByMember.name} ${item.likedByMember.surname}` }}
                      </p>
                    </div>
                  </b-link>
                </template>
              </b-media>
            </template>
            <!-- Column: Element name -->
            <template #cell(element)="{ item }">
              <p class="align-text-top mb-0 pl-25 ml-1 mt-75">{{ getElementName(item.element) }}</p>
            </template>
            <!-- Column: Element type -->
            <template #cell(elementType)="{ item }">
              <p class="align-text-top mb-0 pl-25 ml-1 mt-75">{{ getElementTypeTranslated(item.elementType) }}</p>
            </template>
            <!-- Column: Date -->
            <template #cell(likedAt)="{ item }">
              <p class="align-text-top mb-0 pl-25 ml-1 mt-75">{{ new Date(item.likedAt).toLocaleString() }}</p>
            </template>
          </b-table>
          <div
            v-if="totalCommunityLikes > communityLikes.length"
            class="d-flex border-top"
            :class="currentLikesPage > 1 ? 'justify-content-between' : 'justify-content-end'"
          >
            <b-button
              v-if="currentLikesPage > 1"
              variant="link"
              class="p-0 my-75"
              @click="currentLikesPage -= 1"
            >
              <b-icon-arrow-left-square-fill />
            </b-button>
            <b-button
              v-if="likesLastPage > currentLikesPage"
              variant="link"
              class="p-0 my-75"
              @click="currentLikesPage += 1"
            >
              <b-icon-arrow-right-square-fill />
            </b-button>
          </div>
        </b-modal>
        
        <!-- COMPLETED ITEMS -->
        <b-col
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <b-card body-class="d-flex flex-column justify-content-between">
            <span class="d-flex align-items-center">
              <feather-icon
                size="21"
                :icon="getIcons('completes-seen-in-community')"
                class="b-avatar mb-1 badge-light-primary rounded-circle p-50 mr-1"
              />
              <p>{{ getTitles('completes-seen-in-community') }}</p>
            </span>
            <div class="d-flex justify-content-between flex-wrap align-items-center">
              <div class="d-flex">
                <h2 v-if="totalCommunityCompletes > 0">
                  {{ totalCommunityCompletes }}
                </h2>
                <h2 v-else>0</h2>
              </div>
              <div v-if="totalCommunityCompletes > 0" v-b-modal.modal-completes class="text-primary mb-25">
                <b-icon-card-checklist scale="1.2" class="mr-25" /> {{ $t('backoffice.analytics.member-list') }}
              </div>
            </div>
          </b-card>
        </b-col>
        
        <!-- Completes modal -->
        <b-modal
          id="modal-completes"
          hide-footer
          scrollable
          size="xl"
        >
          <template #modal-header>
            <div class="d-flex flex-column flex-lg-row justify-content-between w-100 align-items-center flex-wrap">
              <div class="d-flex w-100 justify-content-between align-items-center flex-wrap">
                <h5 id="modal-lg___BV_modal_title_" class="modal-title">
                  {{ getTitles('completes-seen-in-community') }}
                </h5>
              </div>
            </div>
            <button
              type="button"
              aria-label="Close"
              class="close order-0 order-lg-1"
              @click="closeModal('modal-completes')"
            >
              ×
            </button>
          </template>
          <b-table
            class="position-relative"
            :items="communityCompletes"
            responsive
            :small="isSmall"
            :fields="completeModalOptions"
            per-page="15"
            show-empty
            empty-text="No matching records found"
          >
            <!-- Column: Member -->
            <template #cell(completedByMember)="{ item }">
              <b-media class="d-flex align-items-center pl-1 ml-25 mt-25">
                <template>
                  <b-link :to="getMemberLocation(item.completedByMember)" class="d-flex align-items-center">
                    <div>
                      <user-avatar :user="item.completedByMember" size="32" />
                    </div>
                    <div class="ml-1">
                      <p class="align-text-top mb-0 pl-25 ml-1">
                        {{ `${item.completedByMember.name} ${item.completedByMember.surname}` }}
                      </p>
                    </div>
                  </b-link>
                </template>
              </b-media>
            </template>
            <!-- Column: Element name -->
            <template #cell(element)="{ item }">
              <p class="align-text-top mb-0 pl-25 ml-1 mt-75">{{ getElementName(item.element) }}</p>
            </template>
            <!-- Column: Element type -->
            <template #cell(elementType)="{ item }">
              <p class="align-text-top mb-0 pl-25 ml-1 mt-75">{{ getElementTypeTranslated(item.elementType) }}</p>
            </template>
            <!-- Column: Date -->
            <template #cell(completedAt)="{ item }">
              <p class="align-text-top mb-0 pl-25 ml-1 mt-75">{{ new Date(item.completedAt).toLocaleString() }}</p>
            </template>
          </b-table>
          <div
            v-if="totalCommunityCompletes > communityCompletes.length"
            class="d-flex border-top"
            :class="currentCompletesPage > 1 ? 'justify-content-between' : 'justify-content-end'"
          >
            <b-button
              v-if="currentCompletesPage > 1"
              variant="link"
              class="p-0 my-75"
              @click="currentCompletesPage -= 1"
            >
              <b-icon-arrow-left-square-fill />
            </b-button>
            <b-button
              v-if="completesLastPage > currentCompletesPage"
              variant="link"
              class="p-0 my-75"
              @click="currentCompletesPage += 1"
            >
              <b-icon-arrow-right-square-fill />
            </b-button>
          </div>
        </b-modal>

        <!-- Export Modal -->
        <b-modal
          id="export-modal-data"
          :title="$t('backoffice.analytics.subtitle')"
          :ok-title="$t('backoffice.analytics.download')"
          ok-only
          :ok-disabled="buttonDisabled"
          @ok="getExportAnalytics()"
        >
          <b-form>
            <b-form-group>
              <label for="export-data" class="font-weight-bold">
                 {{ $t('backoffice.analytics.data-type') }}
              </label>
              <v-select
                id="export-data"
                v-model="exportType"
                :placeholder="$t('form.select-placeholder')"
                :searchable="false"
                :options="exportOptions"
                :clearable="false"
                @change="onChangeSelect"
              />
            </b-form-group>
            <!-- Date Ranges -->
            <b-form-group v-if="exportType === exportOptions[0] || exportType === exportOptions[2]">
              <label for="startDate" class="font-weight-bold">
                 {{ $t('backoffice.analytics.range-label') }} 
              </label>
              <b-form-group>
                <small>{{ $t('calendar.meeting-form.start-date.label') }} {{ $t('events.event-details.local-time') }}*
                </small>
                <VueCtkDateTimePicker
                  v-model="form.startDate.start"
                  :locale="locale"
                  format="YYYY-MM-DD HH:mm"
                  :label="$t('datepicker.select-label')"
                  color="var(--primary)"
                  no-button-now
                  button-color="var(--primary)"
                  @input="validator(form.startDate.start)"
                />
              </b-form-group>
              <b-form-group>
                <small>{{ $t('calendar.meeting-form.end-date.label') }} {{ $t('events.event-details.local-time') }}*
                </small>
                <VueCtkDateTimePicker
                  v-model="form.startDate.end"
                  :locale="locale"
                  :label="$t('datepicker.select-label')"
                  color="var(--primary)"
                  format="YYYY-MM-DD HH:mm"
                  no-button-now
                  button-color="var(--primary)"
                  :min-date="form.startDate.start"
                  @input="validator(form.startDate.end)"
                />
              </b-form-group>
            </b-form-group>
          </b-form>
          <!--template v-if="exportType === exportOptions[3]">
            <div>HOLAAAA</div>
          </template-->
          <!-- Data grouped by user -->
          <template v-if="exportType === $t('backoffice.analytics.classroom-analytics')">
            <b-form-group>
              <label class="font-weight-bold"> {{ $t('backoffice.analytics.grouped-label') }} </label>
              <b-form-checkbox v-model="form.grouped" switch />
            </b-form-group>
            <b-form-group>
              <label class="font-weight-bold"> {{ $t('backoffice.analytics.details-label') }} </label>
              <b-form-checkbox v-model="form.detail" switch />
            </b-form-group>
          </template>
        </b-modal>
      </b-row>
    </template>

    <!-- Attendees modal -->
    <b-modal
      id="modal-attendees"
      hide-footer
      scrollable
      size="lg"
      @show="currentAtendeesPage = 1"
    >
      <template #modal-header>
        <div class="d-flex flex-column flex-lg-row justify-content-between w-100 align-items-center flex-wrap">
          <div class="d-flex w-100 justify-content-between align-items-center flex-wrap">
            <h5 id="modal-lg___BV_modal_title_" class="modal-title">
              {{ $t('backoffice.analytics.on-site-members') }}
            </h5>
            <b-badge variant="primary" class="ml-25 mt-50 font-weight-normal pointer" @click="generateCsvReport('backoffice/members/export/onsite', null)">
              <span v-if="!isLoadingButton"><feather-icon icon="DownloadIcon" class="mr-25" />{{ $t('backoffice.analytics.download') }}</span>
              <b-spinner v-else small label="Sending..." class="spinner" />
            </b-badge>
          </div>
        </div>
        <button
          type="button"
          aria-label="Close"
          class="close order-0 order-lg-1"
          @click="closeModal('modal-attendees')"
        >
          ×
        </button>
      </template>
      <ul class="list-unstyled d-block m-0 mb-1">
        <li v-for="attendee in attendance" :key="attendee.key" class="mt-1 d-flex justify-content-between align-items-center flex-wrap">
          <component :is="attendee.member.username ? 'b-link' : 'span'" :to="getMemberLocation(attendee.member)">
            <user-avatar :user="attendee.member" size="32" class="mr-50" />
            {{ attendee.member.name }} {{ attendee.member.surname }} 
          </component>
          {{ new Date(attendee.attendanceDate).toLocaleString()}}
        </li>
      </ul>
      <div
        v-if="attendance != null ? totalCommunityLikes > attendance.length : false"
        class="d-flex border-top"
        :class="currentAtendeesPage > 1 ? 'justify-content-between' : 'justify-content-end'"
      >
        <b-button
          v-if="currentAtendeesPage > 1"
          variant="link"
          class="p-0 my-75"
          @click="currentAtendeesPage -= 1"
        >
          <b-icon-arrow-left-square-fill />
        </b-button>
        <b-button
          v-if="lastPage > currentAtendeesPage"
          variant="link"
          class="p-0 my-75"
          @click="currentAtendeesPage += 1"
        >
          <b-icon-arrow-right-square-fill />
        </b-button>
      </div>
    </b-modal>

    <!-- Connected members modal -->
    <b-modal
      id="modal-connected"
      size="lg"
      hide-footer
      scrollable
      @show="currentConnectedPage = 1"
    >
      <template #modal-header>
        <div class="d-flex flex-column flex-lg-row justify-content-between w-100 align-items-center flex-wrap">
          <div class="d-flex w-100 justify-content-between align-items-center flex-wrap">
            <h5 id="modal-lg___BV_modal_title_" class="modal-title">
              {{ $t('backoffice.dashboard.connected-members') }}
            </h5>
            <b-badge 
              variant="primary" 
              class="ml-25 mt-50 font-weight-normal pointer" 
              @click="generateCsvReport('backoffice/members/export/online', 'now')"
            >
              <span v-if="!isLoadingButton" >
                <feather-icon icon="DownloadIcon" class="mr-25" />
                {{ $t('backoffice.analytics.download') }}
              </span>
              <b-spinner v-else small label="Sending..." class="spinner" />
            </b-badge>
          </div>
        </div>
        <button
          type="button"
          aria-label="Close"
          class="close order-0 order-lg-1"
          @click="closeModal('modal-connected')"
        >
          ×
        </button>
      </template>
      <ul class="list-unstyled d-block m-0 mb-1">
        <li v-for="attendee in membersConnected" :key="attendee.key" class="mt-1 d-flex justify-content-between align-items-center flex-wrap">
          <component :is="attendee.username ? 'b-link' : 'span'" :to="getMemberLocation(attendee)">
            <user-avatar :user="attendee" size="32" class="mr-50" />
            {{ attendee.name }} {{ attendee.surname }}
          </component>
          {{ new Date(attendee.connectedAt).toLocaleString()}}
        </li>
      </ul>
      <div
        v-if="totalMembersConnected > membersConnected.length"
        class="d-flex border-top"
        :class="currentConnectedPage > 1 ? 'justify-content-between' : 'justify-content-end'"
      >
        <b-button
          v-if="currentConnectedPage > 1"
          variant="link"
          class="p-0 my-75"
          @click="currentConnectedPage -= 1"
        >
          <b-icon-arrow-left-square-fill />
        </b-button>
        <!--<b-button
          v-if="connectedLastPage > currentConnectedPage"
          variant="link"
          class="p-0 my-75"
          @click="currentConnectedPage += 1"
        >
          <b-icon-arrow-right-square-fill />
        </b-button>-->
      </div>
    </b-modal>
    
    <loading-modal v-model="isLoadingButton" v-ripple.400="'rgba(234, 84, 85, 0.15)'" />
  </backoffice-container>
</template>

<script>
import { RoomProvider } from '@copernicsw/community-common';
import { LikeTypeTranslated } from '@copernicsw/community-common';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';
import ErrorPlaceholder from '@/assets/images/placeholders/light/acceso_restringido.svg';
import ConnectedMembers from '@/backoffice/components/charts/ConnectedMembers.vue';
import RegisterEvolution from '@/backoffice/components/charts/RegisterEvolution.vue';
import ConnectedMembersLastMinute from '@/backoffice/components/charts/ConnectedMembersLastMinute.vue';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import LoadingModal from '@core/components/modal/LoadingModal.vue';
import Ripple from 'vue-ripple-directive';
import { randomString } from '@/@core/utils/utils';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';

export default {
  components: {
    vSelect,
    ConnectedMembers,
    ConnectedMembersLastMinute,
    RegisterEvolution,
    UserAvatar,
    LoadingModal,
    BackofficeContainer,
  },
  mixins: [ToastNotificationsMixin],
  directives: {
    Ripple,
  },
  data() {
    return {
      isSmall: false,
      totalMembersLastWeek: 0,
      isLoading: false,
      form: {
        startDate: {
          start: null,
          end: null,
        },
        grouped: false,
        detail: false,
      },
      isValid: null,
      exportType: this.$t('backoffice.analytics.general'),
      isStaff: false,
      chartRange: {},
      totalMembers: 0,
      currentAtendeesPage: 1,
      currentConnectedPage: 1,
      currentLikesPage: 1,
      currentCompletesPage: 1,
      keyForAnalytics: "rand",
      analyticsLastMinuteKey: "rand2",
      likesPerPage: 20,
      membersConnectedRN: {},
      isLoadingButton: false,
    };
  },
  computed: {
    attendance() {
      return this.$store.getters.attendance.paginated[this.currentAtendeesPage] || [];
    },
    totalAttendance() {
      return this.$store.getters.attendance.meta?.total;
    },
    totalMembersConnected() {
      return this.membersConnectedRN?.total || 0;
    },
    membersConnected() {
      return this.membersConnectedRN?.data || [];
    },
    likesLastPage() {
      return this.$store.getters.communityLikes.meta.last_page;
    },
    communityLikes() {
      return this.$store.getters.communityLikes.paginated[this.currentLikesPage] || [];
    },
    totalCommunityLikes() {
      return this.$store.getters.communityLikes?.meta?.total;
    },
    likeModalOptions() {
      return [
        {
          key: 'likedByMember',
          label: this.$t('backoffice.analytics.liked-by-member'),
        },
        {
          key: 'element',
          label: this.$t('backoffice.analytics.element-liked'),
        },
        {
          key: 'elementType',
          label: this.$t('backoffice.analytics.typeof-element-liked'),
        },
        {
          key: 'likedAt',
          label: this.$t('backoffice.analytics.liked-at-date'),
        },
      ];
    },
    completesLastPage() {
      return this.$store.getters.completedItems.meta.last_page;
    },
    communityCompletes() {
      return this.$store.getters.completedItems.paginated[this.currentCompletesPage] || [];
    },
    totalCommunityCompletes() {
      return this.$store.getters.completedItems?.meta?.total;
    },
    completeModalOptions() {
      return [
        {
          key: 'completedByMember',
          label: this.$t('backoffice.analytics.completed-by-member'),
        },
        {
          key: 'element',
          label: this.$t('backoffice.analytics.element-completed'),
        },
        {
          key: 'elementType',
          label: this.$t('backoffice.analytics.typeof-element-completed'),
        },
        {
          key: 'completedAt',
          label: this.$t('backoffice.analytics.completed-at-date'),
        },
      ];
    },
    lastPage() {
      return this.$store.getters.attendance.meta.last_page;
    },
    connectedLastPage() {
      return this.$store.getters.connectedMembers.meta.last_page;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    mainCommunity() {
      return this.$store.getters.mainCollective;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    overviewData() {
      return this.$store.getters.overview;
    },
    analyticsData() {
      return this.$store.getters.analytics;
    },
    analyticsSeries() {
      return this.$store.getters.analyticsSeries;
    },
    coordinationLocation() {
      return process.env.VUE_APP_COORDINATION_CENTER.replace(
        '{main_community.slug}',
        this.mainCommunity.slug || this.community.slug,
      ).replace('{community.slug}', this.community.slug);
    },
    administrationLocation() {
      return process.env.VUE_APP_BACKOFFICE_TEMPLATE_URL.replace(
        '{collectiveSubdomain}',
        this.community.subdomain || 'app',
      ).replace('{collectiveSlug}', this.community.slug);
    },
    googleAnalytics() {
      return this.$store.getters.analyticsGA;
    },
    googleRangeAnalytics() {
      return this.$store.getters.analyticsRangeGA;
    },
    membersAnalytics() {
      const membersData = { ...this.overviewData, ...this.googleAnalytics };
      delete membersData.newMembersPerDay;
      delete membersData.verifiedRatio;
      return membersData;
    },
    eventsAnalytics() {
      const eventsAnalytics = this.analyticsData.filter(({ name }) => name.includes('event'));
      return eventsAnalytics.filter((item) => item.value !== null);
    },
    commentsAnalytics() {
      const commentAnalytics = this.analyticsData.filter(({ name }) => name.includes('comment'));
      return commentAnalytics.filter((item) => item.value !== null);
    },
    othersAnalytics() {
      const othersAnalytics = this.analyticsData.filter(
        ({ name }) => !name.includes('event') && !name.includes('member') && !name.includes('user'),
      );
      return othersAnalytics.filter((item) => item.value !== null);
    },
    analyticsLastMinute() {
      return this.$store.getters.analyticsLastMinute;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    // roomsData() {
    //   return this.$store.getters.rooms;
    // },
    // hasClassroom() {
    //   return this.roomsData.unpaginated.some(({ app }) => app === RoomProvider.bbb || app === 5);
    // },
    exportOptions() {
      return [
        this.$t('backoffice.analytics.general'),
        this.$t('backoffice.analytics.members-currently-connected'),
        this.$t('backoffice.analytics.members-connected-dates'),
        this.$t('backoffice.analytics.meetings')
      ];
    },
    buttonDisabled() {
      if (this.exportType === this.exportOptions[0] || this.exportType === this.exportOptions[2]) {
        return !this.form.startDate.start || !this.form.startDate.end;
      } else {
        return false;
      }
    },
    imgUrl() {
      return ErrorPlaceholder;
    },
  },
  watch: {
    currentAtendeesPage() {
      this.getAttendance();
    },
    currentLikesPage() {
      this.getCommunityLikes();
    },
    currentLikesPage() {
      this.getCommunityCompletes();
    },
  },
  async created() {
    this.isLoading = true;
    this.isStaff = this.currentCollective.isStaff;
    this.$socket.emit("getConnectedOnes", this.currentCollective.slug);
    this.getAttendance();
    this.getCommunityLikes();
    this.getCommunityCompletes();
    this.getChartRange([`${new Date().getDate()-7}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`, `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`])
    this.getChartRangeGA();
    this.getChartRangeGA('7daysAgo', '6daysAgo');
    this.getChartRangeGA('6daysAgo', '5daysAgo');
    this.getChartRangeGA('5daysAgo', '4daysAgo');
    this.getChartRangeGA('4daysAgo', '3daysAgo');
    this.getChartRangeGA('3daysAgo', '2daysAgo');
    this.getChartRangeGA('2daysAgo', '1daysAgo');
    this.getChartRangeGA(
      '1daysAgo',
      new Date()
        .toISOString()
        .toLocaleString('en-GB')
        .substring(0, 10),
    );
    this.getOverview();
    //this.fetchRooms();  // esto para qué??
    // call GA api every 60s
    setInterval(() => {
      this.getTotalMembers();
    }, 60000);
    this.isLoading = false;
  },
  mounted() {
    // call GA api every 60s
    this.getRangeGA();
  },
  destroyed() {
    clearInterval(this.getRangeGA());
  },
  sockets: {
    async connectedOnes(data){
      this.membersConnectedRN = data;
      await this.getAnalytics();
      this.keyForAnalytics = randomString(10);
      this.analyticsLastMinuteKey = randomString(10);
    },
    newConnectionCommunity(data){
      if(this.membersConnectedRN.find((x) => x.key === data.key) == null){
        this.membersConnectedRN.data.push(data);
        this.keyForAnalytics = randomString(10);
      }
    },
    disconnectedUserCommunity(data){
      // Hacer la logica de la desconexión
      const index  = this.membersConnectedRN.findIndex((x) => x.key === data.key)
      if(index > -1){
        this.membersConnectedRN.data.splice(index, 1);
        this.keyForAnalytics = randomString(10);
      }
    }
  },
  methods: {
    getTotalMembers(){
      this.totalMembers = this.totalMembersConnected;
    },
    async getAttendance() {
      this.currentAtendeesPage === 1 ? this.isLoading = true : '';
      await this.$store.dispatch('getItems', {
        itemType: 'communities/attendance/onsite',
        customName: 'attendance',
        page: this.currentAtendeesPage,
        forceAPICall: true,
      });
      this.isLoading = false;
    },
    async getCommunityLikes() {
      await this.$store.dispatch('getItems', {
        itemType: 'likes',
        customName: 'communityLikes',
        page: this.currentLikesPage,
        forceAPICall: true,
      });
      this.isLoading = false;
    },
    async getCommunityCompletes() {
      await this.$store.dispatch('getItems', {
        itemType: 'completes',
        customName: 'completedItems',
        page: this.currentCompletesPage,
        forceAPICall: true,
        requestConfig: {
          withHierarchy: 1
        }
      });
      this.isLoading = false;
    },
    getAnalyticsSummary() {
      this.$store.dispatch('getAnalyticsSummary');
    },
    async getSeriesCounters() {
      // To get the metrics in csv
      await this.$store.dispatch('getSeriesCounters', {
        start: new Date(this.form.startDate.start).getTime(),
        end: new Date(this.form.startDate.end).getTime(),
        step: 1800000,
      });
      await this.getRangeGA();
      this.downloadCsv();
    },
    async getRangeGA() {
      // To get the metrics in csv
      const formStartDateStart = this.form?.startDate?.start;
      console.log('this.form?.startDate?.start', this.form?.startDate?.start);
      var startDate = formStartDateStart ? new Date(formStartDateStart) : new Date();
      startDate = startDate.setDate(startDate.getDate() - 1).toISOString().toLocaleString('en-GB').substring(0, 10);
      console.log('date1', startDate);
      var endDate = formStartDateStart ? new Date(formStartDateStart) : new Date()
      endDate = endDate.setDate(endDate.getDate() + 1).toISOString().toLocaleString('en-GB').substring(0, 10);
      console.log('date2', endDate);

      await this.$store.dispatch('getRangeAnalyticsGA', {
        startDate: startDate,
        endDate: endDate,
      });
      this.form = {
        startDate: {
          start: null,
          end: null,
        },
        grouped: false,
        detail: false,
      };
    },
    getTitles(data) {
      const itemName = data.name || data;
      const title = {
        totalUsersConnectedToday: this.$t('backoffice.dashboard.total-users-connected-today'),
        'members-joined-per-community': this.$t('backoffice.dashboard.joined-members'),
        'members-left-per-community': this.$t('backoffice.dashboard.left-members'),
        'members-per-community': this.$t('backoffice.dashboard.total-members'),
        'events-per-community': this.$t('backoffice.dashboard.total-events'),
        'event-duration-per-community': this.$t('backoffice.dashboard.events-duration'),
        'likes-seen-in-community': this.$t('backoffice.dashboard.total-likes'),
        'completes-seen-in-community': this.$t('backoffice.dashboard.total-completes'),
        'created-resources-per-community': this.$t('backoffice.dashboard.resources-created'),
        'comments-seen-in-community': this.$t('backoffice.dashboard.total-comments'),
        'comments-modified-seen-in-community': this.$t('backoffice.dashboard.modified-comments'),
      };
      return title[itemName];
    },
    getElementName(data) {
      let name = data.name || data.title || data.message || data.comment;

      if (typeof (name) === 'string') {
        name = name.replace(/<\/?[^>]+(>|$)/g, "");
        if (name.includes("{\"")) {
          name = JSON.parse(name);
          name = Object.values(name)[this.locale] ? Object.values(name)[this.locale] : Object.values(name)[0];
        }
      }

      if (typeof (name) === 'object') {
        name = Object.values(name)[this.locale] ? Object.values(name)[this.locale] : Object.values(name)[0];
      }

      return name;
    },
    getElementTypeTranslated(type) {
      return LikeTypeTranslated[type][this.locale];
    },
    getIcons(data) {
      const itemName = data.name || data;
      const title = {
        'events-per-community': 'CalendarIcon',
        'event-duration-per-community': 'ClockIcon',
        'likes-seen-in-community': 'ThumbsUpIcon',
        'completes-seen-in-community': 'CheckCircleIcon',
        'created-resources-per-community': 'AlignLeftIcon',
        'comments-seen-in-community': 'MessageSquareIcon',
        'comments-modified-seen-in-community': 'Edit2Icon',
      };
      return title[itemName];
    },
    downloadCsv() {
      let csv = '';
      // Google Analytics info
      this.analyticsSeries.unshift({
        counter: { name: 'totalUsersConnected' },
        values: [
          { value: this.form.startDate.start ? this.googleRangeAnalytics.total : this.googleAnalytics.totalUsersNow },
        ],
      });
      // this.analyticsSeries.unshift({ counter: { name: 'totalUsersNow' }, values: [{ value: this.googleAnalytics.totalUsersNow }] });

      // Columns titles
      this.analyticsSeries.unshift({ counter: { name: 'Metrics' }, values: [{ value: 'Values' }] });
      this.analyticsSeries.forEach((row) => {
        csv
          += row.counter.name === 'event-duration-per-community'
            ? `${row.counter.name}, ${(row.values[0]?.value / 60).toFixed(2)} min.`
            : `${row.counter.name}, ${row.values[0]?.value}`;
        csv += '\n';
      });
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Analytics.csv';
      hiddenElement.click();
      // Remove columns titles so that multiple titles are not generated when clicking the button again
      this.analyticsSeries.shift();
    },
    async fetchRooms() {
      await this.$store.dispatch('getItemsNode', {
        itemType: 'rooms',
        page: 1,
        // Workaround to ensure that the main room is located
        // TODO: request second page if mainRoom is not found
        perPage: 1000,
      });
    },
    validator() {
      this.isValid = true;
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    async getAnalytics() {
      await this.$store.dispatch('getAnalyticsGA', {num: this.totalMembersConnected});
      await this.getAnalyticsSummary();
    },
    async getChartRangeGA(start, end) {
      // chart
      const response = await this.$store.dispatch('getRangeAnalyticsGA', {
        startDate: start,
        endDate: end,
      });
      //this.chartRange.push(response.total);
    },
    async getChartRange(dates) {
      // chart
      const response = await this.$store.dispatch('getItems', {
        itemType: "onlineAttendees",
        customName: "connectedMembers",
        page: 1,
        requestConfig: {
          dates
        }
      });

      this.chartRange = response || {};
      this.totalMembersLastWeek = response?.length || 0;

      
    },
    hasTitle(items) {
      const analytics = items.filter((data) => data.value != null);
      return analytics.length > 0;
    },
    async getOverview() {
      await this.$store.dispatch('getOverview');
    },
    getRatio(value) {
      if (value) {
        const number = (value * 100) / this.membersAnalytics.registeredMembers;
        return number.toFixed(2);
      }
      return 0;
    },
    getMemberLocation(attendee) {
      return {
        name: 'backoffice-members-details',
        params: { username: attendee.username, communityId: this.$route.params.communityId },
      };
    },
    closeModal(id) {
      this.$bvModal.hide(id);
    },
    onChangeSelect(value){
      console.log('onChangeSelect - value:', value);
    },
    onClickSelect(value){
      console.log('onClickSelect - value:', value);
    },
    onBlurSelect(value){
      console.log('onBlurSelect - value:', value);
    },
    async getExportAnalytics() {
      console.log('exportType:', this.exportType);
      // Old
      // if (this.exportType === this.$t('backoffice.analytics.classroom-analytics')) {
      //   const startDate = new Date(this.form.startDate.start).toISOString();
      //   const endDate = new Date(this.form.startDate.end).toISOString();
      //   window.location.href = `https://classroom.nectios.com/bebop/api/v1/get_events?isGrouped=${this.form.grouped}&isDetailed=${this.form.detail}&since=${startDate}&until=${endDate}&workspaceName=${this.$store.getters.currentCollective.key}`;
      //   this.notifySuccess(this.$t('backoffice.analytics.messages.success'));
      //   this.form = {
      //     startDate: {
      //       start: null,
      //       end: null,
      //     },
      //     grouped: false,
      //     detail: false,
      //   };
      //   this.isValid = null;
      // } else if (this.$t('backoffice.analytics.classroom-general') && (this.exportType != this.exportOptions[1]) && (this.exportType != this.exportOptions[2]) && (this.exportType != this.exportOptions[3])) {
      //   this.getSeriesCounters();
      //   this.notifySuccess(this.$t('backoffice.analytics.messages.success'));
      //   this.isValid = null;
      // }
      console.log('Clica el botón OK');
      console.log('exportOptions:', this.exportOptions);
      if (this.exportType === this.exportOptions[1]) {
        await this.generateCsvReport('backoffice/members/export/online', 'now')
      }
      if (this.exportType === this.exportOptions[2]) {
        if (this.form.startDate.end === null){
          this.form.startDate.end = null;
        }
        await this.generateCsvReport('backoffice/members/export/online', this.form)
      }
      if (this.exportType === this.exportOptions[3]) {
        console.log('Holaa');
        await this.generateCsvReport('backoffice/meetings/export', 'now')
      }
    },
    async generateCsvReport(endpoint, connection) {
      this.isLoadingButton = true;
      let response = null;

      if (connection === 'now' || !connection) {
        response = await this.$store.dispatch('exportFiles', {
          itemType: endpoint,
        });
      } else {
        response = await this.$store.dispatch('exportFilesExtended', {
          itemType: endpoint,
          params: connection.startDate
        });
      }
      const currentDate = new Date(); 
      const timestamp = currentDate.getTime();
      let fileName = 'members';
      if(endpoint === 'backoffice/meetings/export'){
        fileName = 'meetings';
      }
      fileName += '_' + timestamp + '.csv';
      //REVIEW: this should be automatic
      setTimeout(this.downloadCsv(response, fileName), 20000);  
      this.isLoadingButton = false;
    },
    downloadCsv(response, fileName) {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = response.data.file;
      hiddenElement.target = '_blank';
      hiddenElement.download = fileName;
      hiddenElement.click();
    },
  },
};
</script>
