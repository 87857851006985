<script>
import { Line } from 'vue-chartjs';

export default {
  components: { Line },
  extends: Line,
  props: {
    lastMinute: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    chartdata: {
      labels: [

      ],

      datasets: [
        {
          label: 'Members',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: '#55bcc7',
          borderWidth: 2,
          tension: 0.3,
        },
      ],
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              suggestedMax: 60,
              stepSize: 10,
            },
          }],
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 7,
          backgroundColor: '#55bcc7',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    },
  }),

  mounted() {
    this.chartdata.datasets[0].data = this.lastMinute;
    this.chartdata.datasets[0].label = this.$t('members.title');
    this.options.scales.yAxes[0].ticks.suggestedMax = this.total;
    if (this.total < 50) {
      this.options.scales.yAxes[0].ticks.stepSize = 1;
    } else if (this.total > 50 && this.total < 250) {
      this.options.scales.yAxes[0].ticks.stepSize = 10;
    } else if (this.total > 250 && this.total < 800) {
      this.options.scales.yAxes[0].ticks.stepSize = 30;
    } else {
      this.options.scales.yAxes[0].ticks.stepSize = 50;
    }
    this.chartdata.labels.unshift(this.$t('backoffice.analytics.now'));
    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    lastMinute(newValue) {
      this.chartdata.labels.slice(0, 14);
      if (this.chartdata.labels.length === 15) {
        this.chartdata.labels.shift();
      }
      this.chartdata.labels.unshift(this.$t('backoffice.analytics.minutes-ago', { minutes: this.chartdata.datasets[0].data.length - 1 }));
      this.chartdata.datasets[0].data = newValue;
      this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>
