<script>
import { Line } from 'vue-chartjs';

export default {
  components: { Line },
  extends: Line,
  props: {
    chartRange: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    chartdata: {
      labels: [
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-7).toLocaleString('en-GB').substring(0,10),
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-6).toLocaleString('en-GB').substring(0,10),
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-5).toLocaleString('en-GB').substring(0,10),
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-4).toLocaleString('en-GB').substring(0,10),
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-3).toLocaleString('en-GB').substring(0,10),
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-2).toLocaleString('en-GB').substring(0,10),
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-1).toLocaleString('en-GB').substring(0,10),
        new Date().toLocaleString('en-GB').substring(0,10),
      ],

      datasets: [
        {
          label: 'Members',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: '#55bcc7',
          borderWidth: 2,
          tension: 0.3,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 7,
          backgroundColor: '#55bcc7',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              suggestedMax: 60,
              stepSize: 10,
            },
          }],
      },
    },
  }),

  mounted() {
    this.chartdata.datasets[0].data = [];
    for(let row of Object.values(this.chartRange)){
      this.chartdata.datasets[0].data.push(row);
    }
    this.chartdata.datasets[0].label = this.$t('members.title');
    this.options.scales.yAxes[0].ticks.suggestedMax = this.total;
    if (this.total < 50) {
      this.options.scales.yAxes[0].ticks.stepSize = 1;
    } else if (this.total > 50 && this.total < 250) {
      this.options.scales.yAxes[0].ticks.stepSize = 10;
    } else if (this.total > 250 && this.total < 800) {
      this.options.scales.yAxes[0].ticks.stepSize = 30;
    } else {
      this.options.scales.yAxes[0].ticks.stepSize = 50;
    }
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
