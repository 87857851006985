<script>
import { Line } from 'vue-chartjs';

export default {
  components: { Line },
  extends: Line,
  props: {
    chartRange: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    chartdata: {
      labels: [],

      datasets: [
        // Registered
        {
          label: 'Registered Members',
          data: [],
          fill: false,
          borderColor: '#55bcc7',
          backgroundColor: '#55bcc7',
          borderWidth: 2,
          tension: 0.3,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              suggestedMax: 60,
              stepSize: 10,
            },
          }],
        xAxes: [
          {
            ticks: {
            },
          }],
      },
    },
  }),

  mounted() {
    this.chartdata.datasets[0].label = this.$t('backoffice.analytics.registered-members');
    if (this.chartRange.length > 100) {
      const array = [];
      this.chartRange.map((item, index) => {
        if (index === 0) {
          array.push(item)
        } else {
            if (array[array.length -1][0].includes(item[0].substring(0,8))) {
              array[array.length -1][1] = array[array.length -1][1] + item[1]
            } else {
              array.push(item)
            }
          }
      });
      this.chartdata.datasets[0].data = array.map((item) => item[1]);
      this.chartdata.labels = array.map((item) => new Date(item[0]).toLocaleString('en-GB').substring(3,10));
    } else {
      this.chartdata.datasets[0].data = this.chartRange.map((item) => item[1]);
      this.chartdata.labels = this.chartRange.map((item) => new Date(item[0]).toLocaleString('en-GB').substring(0,10));
    }
    this.options.scales.yAxes[0].ticks.suggestedMax = this.total;
    if (this.total < 50) {
      this.options.scales.yAxes[0].ticks.stepSize = 1;
    } else if (this.total > 50 && this.total < 250) {
      this.options.scales.yAxes[0].ticks.stepSize = 10;
    } else if (this.total > 250 && this.total < 800) {
      this.options.scales.yAxes[0].ticks.stepSize = 30;
    } else {
      this.options.scales.yAxes[0].ticks.stepSize = 50;
    }
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
